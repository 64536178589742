import React, { useState, useEffect } from "react";
import { commonAPICall } from "../../services/services";
import FileSaver from "file-saver";
import moment from "moment";
import { FaRegFilePdf } from "react-icons/fa6";
import { Button } from "react-bootstrap";
export default function PreviewKenya() {
  const [data, setData] = useState({});
  const [addressInKenya, setAddressInKenya] = useState([]);
  const [emergencyContact, setEmergencyContact] = useState([]);
  const [groupMemberDetails, setGroupMemberDetails] = useState([]);
  const [reqiuredDocs, setRequiredDocs] = useState([]);

  useEffect(() => {
    const previewData = async () => {
      const id = window.location.pathname.split("/");
      console.log(id);
      try {
        const result = await commonAPICall(
          "/kn/kn_Application_full_preview",
          "post",
          {
            temporary_id: id[3],
          }
        );
        setData(result.data);
        setAddressInKenya(result.addressInKenya);
        setEmergencyContact(result.emergencyContact);
        setGroupMemberDetails(result.groupMemberDetails);
        setRequiredDocs(result.data.groupMemberDetails)
      } catch (err) {
        console.error(err);
      }
    };
    previewData();
  }, []);

  const downloadURI = (e, fileUrl, download) => {
    e.preventDefault();
    FileSaver.saveAs(fileUrl, download);
  };

  return (
    <>
      <div className="preview_table_wrapper">
        <div className="title_box">
          <h3>Your eTV Application Details</h3>
        </div>

        {/* {applicationViewData.map((data) => ( */}
        <div className="datatab_d bg-light border">
          <div
            className="viewdata text-white"
            style={{ backgroundColor: "orangered" }}
          >
            {" "}
            Application id no.{" "}
          </div>
          <div
            className="viewdata text-white"
            style={{ backgroundColor: "orangered" }}
          >
            {" "}
            {data.temporary_id}
          </div>
          <div className="viewdata">Phone</div>
          <div className="viewdata">{data.telephone}</div>
          <div className="viewdata"> Ip Address </div>
          <div className="viewdata"> {data.ip_address}</div>
          <div className="viewdata"> Address </div>
          <div className="viewdata"> {data.address}</div>
          <div className="viewdata"> Visa Type </div>
          <div className="viewdata"> {data.visa_type}</div>
          <div className="viewdata"> Occupation </div>
          <div className="viewdata"> {data.occupation}</div>

          {groupMemberDetails.map((item, index) => {
            return <>
              {groupMemberDetails.length > 1 && <div className="viewdata orange">Member {index + 1}</div>}
              <div className="viewdata"> Full Name </div>
              <div className="viewdata"> {item.full_name}</div>
              <div className="viewdata"> Date of Birth </div>
              <div className="viewdata"> {moment(item.date_of_birth).format('DD/MM/YYYY')}</div>
              {/* <div className="viewdata"> Document Number </div>
              <div className="viewdata"> {item.document_no}</div>
              <div className="viewdata"> Validity </div>
              <div className="viewdata"> {moment(item.validity).format('DD/MM/YYYY')}</div> */}
              <div className="viewdata"> Country of Birth </div>
              <div className="viewdata"> {item.country_of_birth}</div>
              <div className="viewdata"> What was your nationality at Birth </div>
              <div className="viewdata"> {item.nationality}</div>
              <div className="viewdata"> Is your trip financed by a third party, which is not your employer nor a government? </div>
              <div className="viewdata"> {item.trip_finance}</div>
              <div className="viewdata"> Have you ever been convicted of any offence, under any system of law, in the past 5 years? </div>
              <div className="viewdata"> {item.any_offence}</div>
              <div className="viewdata"> Have you ever been previously denied entry to Kenya? </div>
              <div className="viewdata"> {item.denied_entry_in_kenya}</div>
              <div className="viewdata"> What is your marital status? </div>
              <div className="viewdata"> {item.marital_status}</div>
              <div className="viewdata"> Have you previously travelled to Kenya? </div>
              <div className="viewdata"> {item.previously_travel_to_kenya}</div>
            </>
          })}

          <div className="viewdata orange">Emergency Contacts</div>
          {emergencyContact.map((item, index) => {
            return <>
              {emergencyContact.length > 1 && <div className="viewdata suborange">Contact {index + 1}</div>}
              <div className="viewdata"> Full Name </div>
              <div className="viewdata"> {item.full_name}</div>
              <div className="viewdata"> Phone Number </div>
              <div className="viewdata"> {item.mobile_no}</div>
            </>
          })}
          <div className="viewdata orange">Trip Information</div>
          <div className="viewdata">Application Type</div>
          <div className="viewdata">{data.application_type}</div>
          <div className="viewdata">Reason for Travel</div>
          <div className="viewdata">{data.reason_for_travel}</div>
          <div className="viewdata">Arrival Date</div>
          <div className="viewdata">{moment(data.arrival_date).format('DD/MM/YYYY')}</div>
          <div className="viewdata">Arriving by</div>
          <div className="viewdata">{data.arrival_by}</div>
          {data.arrival_place && <>
            <div className="viewdata">Arrival {data.arrival_by === 'Arriving by Sea' ? 'Seaport' : data.arrival_by === 'Arriving by Air' ? 'Airport' : 'Land'} in Kenya</div>
            <div className="viewdata">{data.arrival_place}</div>
          </>}
          {data.arrival_by === 'Arriving by Land' && <>
            <div className="viewdata">Land Border Crossing</div>
            <div className="viewdata">{data.arrival_land_bord_crossing}</div>
          </>}
          {data.arrival_airline && <>
            <div className="viewdata">Airline</div>
            <div className="viewdata">{data.arrival_airline}</div>
          </>}
          {data.arrival_flight && <>
            <div className="viewdata">Flight</div>
            <div className="viewdata">{data.arrival_flight}</div>
          </>}
          {data.arrival_vessel && <>
            <div className="viewdata">Vessel</div>
            <div className="viewdata">{data.arrival_vessel}</div>
          </>}
          <div className="viewdata">Departure Date</div>
          <div className="viewdata">{moment(data.departure_date).format('DD/MM/YYYY')}</div>
          <div className="viewdata">Departing by</div>
          <div className="viewdata">{data.departure_by}</div>
          {data.departure_place && <>
            <div className="viewdata">Departure {data.departure_by === 'Departing by Sea' ? 'Seaport' : data.departure_by === 'Departing by Land' ? 'Land' : 'Airport'} in Kenya</div>
            <div className="viewdata">{data.departure_place}</div>
          </>}
          {data.departure_airline && <>
            <div className="viewdata">Airline</div>
            <div className="viewdata">{data.departure_airline}</div>
          </>}
          {data.departure_flight && <>
            <div className="viewdata">Flight</div>
            <div className="viewdata">{data.departure_flight}</div>
          </>}
          <div className="viewdata">Country of origin for trip</div>
          <div className="viewdata">{data.departure_country_of_origin}</div>
          <div className="viewdata">Will you be bringing into Republic of Kenya currency or monetary instruments of a value greater than $5000 or foreign equivalent?</div>
          {data.bring_value_greater ? <div className="viewdata">{data.bring_value_greater}</div> : groupMemberDetails.length > 0 && groupMemberDetails.map(el => <div className="viewdata">{el.bring_value_greater}</div>)}

          {addressInKenya.length > 1 && <div className="viewdata orange">Address(es) in Kenya</div>}
          {addressInKenya.map((item, index) => {
            console.log();
            return <>
              {addressInKenya.length > 1 && <div className="viewdata suborange">Address {index + 1}</div>}
              <div className="viewdata">Kenya Address</div>
              <div className="viewdata">{item.address}</div>
              <div className="viewdata">From Date</div>
              <div className="viewdata">{moment(item.from_date).format('DD/MM/YYYY')}</div>
              <div className="viewdata">To Date</div>
              <div className="viewdata">{moment(item.to_date).format('DD/MM/YYYY')}</div>
            </>
          })}
          {/*  document  */}

          <div className="viewdata orange">Documents</div>

          {reqiuredDocs.map((doc, index) => {
            return <>
              <div className="viewdata"> User Image </div>
              <div className="viewdata d-flex gap-3 align-items-center">
                {" "}
                <img
                  width={150}
                  src={`https://kenyaapi.quickgst.in/${doc.profile_photo}`}
                ></img>{" "}
                <Button
                  variant="success"
                  size="sm"
                  onClick={(e) =>
                    downloadURI(
                      e,
                      `https://kenyaapi.quickgst.in/${doc.profile_photo}`,
                      doc.profile_photo
                    )
                  }
                >
                  Download
                </Button>
              </div>
            </>
          })}

          {reqiuredDocs.map((doc, index) => {
            return <>
              <div className="viewdata"> Passport Image </div>
              <div className="viewdata d-flex gap-3 align-items-center">
                {" "}
                <img
                  width={150}
                  src={`https://kenyaapi.quickgst.in/${doc.passport_photo}`}
                ></img>{" "}
                <Button
                  variant="success"
                  size="sm"
                  onClick={(e) =>
                    downloadURI(
                      e,
                      `https://kenyaapi.quickgst.in/${doc.passport_photo}`,
                      doc.profile_photo
                    )
                  }
                >
                  Download
                </Button>
              </div>
            </>
          })}

          {data.accomodation_booking_confirm && <>
            <div className="viewdata">Accommodation Booking Confirmation</div>
            <div className="viewdata d-flex gap-3 align-items-center">
              {data.accomodation_booking_confirm.split('.').pop().toLowerCase() === 'pdf' ? <FaRegFilePdf size={30} color="red" /> : <img
                width={150}
                src={`https://kenyaapi.quickgst.in/${data.accomodation_booking_confirm}`} />}

              <Button
                variant="success"
                size="sm"
                onClick={(e) =>
                  downloadURI(
                    e,
                    `https://kenyaapi.quickgst.in/${data.accomodation_booking_confirm}`,
                    data.accomodation_booking_confirm
                  )
                }
              >
                Download
              </Button>
            </div></>}


          {data.airline_booking_confirmation && <>
            <div className="viewdata">Airline/Cruise Booking Confirmation</div>
            <div className="viewdata  d-flex gap-3 align-items-center">
              {data.airline_booking_confirmation.split('.').pop().toLowerCase() === 'pdf' ? <FaRegFilePdf size={30} color="red" /> : <img
                width={150}
                src={`https://kenyaapi.quickgst.in/${data.airline_booking_confirmation}`} />}

              <Button
                variant="success"
                size="sm"
                onClick={(e) =>
                  downloadURI(
                    e,
                    `https://kenyaapi.quickgst.in/${data.airline_booking_confirmation}`,
                    data.airline_booking_confirmation
                  )
                }
              >
                Download
              </Button>
            </div></>}

          {data.identity_card && <>
            <div className="viewdata">Identity Card</div>
            <div className="viewdata  d-flex gap-3 align-items-center">
              {data.identity_card.split('.').pop().toLowerCase() === 'pdf' ? <FaRegFilePdf size={30} color="red" /> : <img
                width={150}
                src={`https://kenyaapi.quickgst.in/${data.identity_card}`} />}

              <Button
                variant="success"
                size="sm"
                onClick={(e) =>
                  downloadURI(
                    e,
                    `https://kenyaapi.quickgst.in/${data.identity_card}`,
                    data.identity_card
                  )
                }
              >
                Download
              </Button>
            </div></>}

        </div>
      </div>
    </>
  );
}
